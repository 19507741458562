import axios from 'axios';

const API_URL = 'https://bxhale-checkout.myshopify.com/api/2023-04/graphql.json'; // Update with your store URL
const ACCESS_TOKEN = 'eb283476f9566c8bb31b163615a8d117'; // Update with your Storefront API access token

const shopifyService = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
    'X-Shopify-Storefront-Access-Token': ACCESS_TOKEN,
  },
});

export const getProducts = async () => {
  const query = `
    {
      products(first: 10) {
        edges {
          node {
            id
            title
            description
            images(first: 5) {
              edges {
                node {
                  originalSrc
                  altText
                }
              }
            }
            variants(first: 5) {
              edges {
                node {
                  id
                  title
                  priceV2 {
                    amount
                    currencyCode
                  }
                }
              }
            }
          }
        }
      }
    }
  `;
  const response = await shopifyService.post('', { query });
  return response.data.data.products.edges;
};


export const getProductDetail = async (productId) => {
  const query = `
    {
      product(id: "${productId}") {
        id
        title
        description
        images(first: 16) {
          edges {
            node {
              originalSrc
              altText
            }
          }
        }
        variants(first: 5) {
          edges {
            node {
              id
              title
              priceV2 {
                amount
                currencyCode
              }
              image {
                originalSrc
                altText
              }
            }
          }
        }
      }
    }
  `;
  const response = await shopifyService.post('', { query });
  return response.data.data.product;
};

export const createCheckout = async (lineItems) => {
    const mutation = `
      mutation($lineItems: [CheckoutLineItemInput!]!) {
        checkoutCreate(input: { lineItems: $lineItems }) {
          checkout {
            id
            webUrl
          }
        }
      }
    `;
    const variables = { lineItems };
    const response = await shopifyService.post('', { query: mutation, variables });
    console.log('GraphQL Response:', response.data, variables);
    
    if (response.data.errors) {
      console.error('GraphQL Errors:', response.data.errors);
    }
    return response.data.data.checkoutCreate.checkout;
  };

  export const updateLineItemQuantity = async (checkoutId, lineItemId, quantity) => {
    const mutation = `
      mutation($checkoutId: ID!, $lineItems: [CheckoutLineItemUpdateInput!]!) {
        checkoutLineItemsUpdate(checkoutId: $checkoutId, lineItems: $lineItems) {
          checkout {
            id
            webUrl
            lineItems(first: 5) {
              edges {
                node {
                  id
                  title
                  quantity
                }
              }
            }
          }
          userErrors {
            field
            message
          }
        }
      }
    `;
    
    const variables = {
      checkoutId,
      lineItems: [
        {
          id: lineItemId,  // The ID of the line item to update
          quantity: quantity  // The new quantity for this line item
        }
      ]
    };
  
    try {
      const response = await shopifyService.post('', { query: mutation, variables });
      
      // Handle errors if they exist
      if (response.data.errors) {
        console.error('GraphQL Errors:', response.data.errors);
        return null;
      }
  
      // Return the updated checkout object
      return response.data.data.checkoutLineItemsUpdate.checkout;
    } catch (error) {
      console.error('Error updating line item quantity:', error);
      return null;
    }
  };
  
  export const addLineItemsToCheckout = async (checkoutId, lineItems) => {
    const mutation = `
      mutation($checkoutId: ID!, $lineItems: [CheckoutLineItemInput!]!) {
        checkoutLineItemsAdd(checkoutId: $checkoutId, lineItems: $lineItems) {
          checkout {
            id
            webUrl
          }
        }
      }
    `;
    const variables = { checkoutId, lineItems };
    const response = await shopifyService.post('', { query: mutation, variables });
    return response.data.data.checkoutLineItemsAdd.checkout;
  };

  export const removeLineItemsFromCheckout = async (checkoutId, lineItemIds) => {
    const mutation = `
      mutation($checkoutId: ID!, $lineItemIds: [ID!]!) {
        checkoutLineItemsRemove(checkoutId: $checkoutId, lineItemIds: $lineItemIds) {
          checkout {
            id
            webUrl
            lineItems(first: 5) {
              edges {
                node {
                  id
                  title
                  quantity
                }
              }
            }
          }
        }
      }
    `;
    const variables = { checkoutId, lineItemIds };
    const response = await shopifyService.post('', { query: mutation, variables });
    return response.data.data.checkoutLineItemsRemove.checkout;
  };


  export const fetchCheckout = async (checkoutId) => {
    const query = `
      {
        node(id: "${checkoutId}") {
          ... on Checkout {
            id
            webUrl
            lineItems(first: 5) {
              edges {
                node {
                  id
                  title
                  quantity
                  variant {
                    image {
                      originalSrc
                      altText
                    }
                    title
                    priceV2{
                      amount
                    }
                  }
                }
              }
            }
          }
        }
      }
    `;
    
    try {
      const response = await shopifyService.post('', { query });
      
      // Check for errors
      if (response.data && response.data.errors) {
        console.error('Shopify GraphQL Errors:', response.data.errors);
        return null;
      }
  
      const checkout = response.data.data.node;
  
      // Calculate the total price
      let totalPrice = 0;
      checkout.lineItems.edges.forEach((item) => {
        const price = parseFloat(item.node.variant.priceV2.amount);
        const quantity = item.node.quantity;
        totalPrice += price * quantity;
      });
  
      // Optionally, you could include the total price in the returned checkout object
      checkout.totalPrice = totalPrice;
  
      return checkout;
    } catch (error) {
      console.error('Error fetching checkout:', error);
      return null;
    }
};
