import React, { useEffect, useState, useRef } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { useProductContext } from '../../products/ProductContext';
import CartItem from './CartItem';
import './Cart.css';

const Cart = () => {
  const { checkout, handleRemoveFromCart, handleUpdateQuantity } = useProductContext();
  const [checkoutCount, setCheckoutCount] = useState(0);
  const [cartOpen, setCartOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [cartHeight, setCartHeight] = useState(window.innerHeight);
  const initialLoad = useRef(true); // Ref to track if it's the first load of the page
  const prevCheckoutCount = useRef(0); // Ref to store the previous checkout count

  const updateCheckoutCount = async () => {
        if (checkout.lineItems?.edges) {
          const newCount = checkout.lineItems.edges.reduce((total, item) => total + item.node.quantity, 0);
          setCartOpen(true);
          setCheckoutCount(newCount);
          prevCheckoutCount.current = newCount;
        }
  };
  
  useEffect(() => {
    if(!initialLoad.current){
      updateCheckoutCount();
    }
    if(checkout && initialLoad.current){
      initialLoad.current = false;
    }
  }, [checkout])

  useEffect(() => {
    if (cartOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'visible';
    }
  }, [cartOpen]);

  const handleCheckout = () => {
    if (checkout) {
      window.location.href = checkout.webUrl;
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      setCartHeight(window.innerHeight); // Update cart height on resize
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (checkout?.lineItems?.edges) {
      setCheckoutCount(checkout.lineItems.edges.reduce((total, item) => total + item.node.quantity, 0));
    }
  }, [checkout]);

  const menuAnimation = {
    initial: windowWidth > 844 
      ? { translateX: '100%', opacity: 0, visibility: 'hidden', pointerEvents: 'none' }  // Hidden initially
      : { translateY: '100%', opacity: 0, visibility: 'hidden', pointerEvents: 'none' },  // Hidden initially
      
    animate: {
      translateX: windowWidth > 844 ? '0%' : undefined,  // Translate to original position
      translateY: windowWidth <= 844 ? '0%' : undefined,
      opacity: 1,  // Opacity 1 when active
      visibility: 'visible',  // Make visible during animation
      pointerEvents: 'auto',  // Allow interaction
      transition: {
        duration: 0.3,  // Duration of the animation
        ease: 'easeInOut',  // Easing function
      },
    },
    
    exit: {
      translateX: windowWidth > 844 ? '100%' : undefined,  // Exit to the right
      translateY: windowWidth <= 844 ? '100%' : undefined,
      opacity: 0,  // Set opacity to 0 during exit
      visibility: 'hidden',  // Hide after exit animation
      pointerEvents: 'none',  // Prevent interaction
      transition: {
        duration: 0.3,
        ease: 'easeInOut',
      },
    },
  };
  
  

  const backgroundAnimation = {
    initial: { opacity: 0 },
    animate: { opacity: cartOpen ? 1 : 0 },
    transition: { duration: 0.2, ease: 'easeInOut' },
  };

  return (
    <>
      <motion.div
        onClick={() => setCartOpen(false)}
        variants={backgroundAnimation}
        initial="initial"
        animate="animate"
        exit={{ opacity: 0 }} // Optional: exit opacity animation
        style={{
          position: 'fixed',   // Fixed positioning to cover the viewport
          top: '-40px',
          left: 0,
          width: '100vw',
          height: '200vh',
          backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background
          pointerEvents: cartOpen ? 'auto' : 'none', // Prevent clicks when closed
        }}
      />    <div className="cart-icon" onClick={() => setCartOpen(!cartOpen)}>
        <img src="/cart.png" alt="Cart" />
        <span className="cart-count">{checkoutCount}</span>
      </div>

      <motion.div
        variants={menuAnimation}
        initial="initial"
        animate={cartOpen ? "animate" : "exit"}
        className="cart-container"
        style={{ height: cartHeight - 50,
          display: cartOpen ? 'flex' : 'none'
        }} // Adjust the height dynamically
      >
        <div className="cart-header">
          <h4>Din varukorg</h4>
          <img src='/close.png' onClick={() => setCartOpen(false)} alt="Close" className="close-icon" />
        </div>

        {checkout?.lineItems?.edges?.length > 0 ? (
          <>
            <ul className="cart-items">
              <div className="cart-summary">
                {checkout.totalPrice < 250 ? (
                  <div>Du är {250 - checkout.totalPrice} kronor ifrån gratis frakt</div>
                ) : (
                  <div>Gratis frakt</div>
                )}
                <div className="progress-bar">
                  <div className="progress" style={{ width: `${Math.min((checkout.totalPrice / 250) * 100, 100)}%` }} />
                  <div className="progress-labels">
                    <span>0kr</span>
                    <span>250kr</span>
                  </div>
                </div>
              </div>
              {checkout.lineItems.edges.map((item) => (
                <CartItem
                  key={item.node.id}
                  item={item}
                  handleRemoveFromCart={handleRemoveFromCart}
                  handleUpdateQuantity={handleUpdateQuantity}
                />
              ))}

              <div className="cart-footer">
                <div className="cart-summary-item">
                  <span>Sub total</span>
                  <span>{checkout.totalPrice} kr</span>
                </div>
                <div className="cart-summary-item">
                  <span>Beräknad frakt</span>
                  <span>{checkout.totalPrice < 250 ? '79kr' : 'Gratis'}</span>
                </div>
                <div className="cart-summary-total">
                  <span>Totalt</span>
                  <span>{checkout.totalPrice + (checkout.totalPrice < 250 ? 79 : 0)} kr</span>
                </div>
              </div>

            </ul>

            <div className="checkout-button-container">
                <button className="checkout-button" onClick={handleCheckout}>
                  Gå till kassan
                </button>
            </div>
          </>
        ) : (
          <div className="cart-empty">Din varukorg är tom</div>
        )}
      </motion.div>
    </>
  );
};

export default Cart;
