import React, { useState } from 'react';

const CartItem = ({ item, handleRemoveFromCart, handleUpdateQuantity }) => {
  // Local loading state for this specific cart item
  const [loading, setLoading] = useState(false); 

  // Handle remove with loading animation only for this item
  const handleRemoveClick = async () => {
    setLoading(true); // Start loading for this item
    try {
      await handleRemoveFromCart(item.node.id); // Perform removal action
    } finally {
      setLoading(false); // Stop loading after the action is completed
    }
  };

  // Handle quantity change with loading animation only for this item
  const handleQuantityChange = async (e) => {
    const newQuantity = parseInt(e.target.value);
    setLoading(true); // Start loading for this item
    try {
      await handleUpdateQuantity(item.node.id, newQuantity); // Perform quantity update
    } finally {
      setLoading(false); // Stop loading after the action is completed
    }
  };

  return (
    <li className="cart-item" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '20px', width: '100%', position: 'relative' }}>
      {/* Loading Overlay - Only appears on the specific item when it's being updated */}
      {loading && (
        <div className="loading-overlay" style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', background: 'rgba(255, 255, 255, 0.7)', zIndex: 10, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        </div>
      )}

      {/* Product Image */}
      {item.node.variant && item.node.variant.image ? (
        <img
          src={item.node.variant.image.originalSrc}
          alt={item.node.variant.image.altText || item.node.title}
          className="cart-item-image"
          style={{ width: '120px', marginRight: '10px' }}
        />
      ) : (
        <img
          src="/placeholder-image.png"
          alt={item.node.title}
          className="cart-item-image"
          style={{ width: '80px', marginRight: '10px' }}
        />
      )}

      {/* Item Details */}
      <div className="cart-item-details" style={{ flex: 1, flexDirection: 'column', gap: '5px', height: '150px' }}>
        <span className="cart-item-title" style={{ display: 'block', marginBottom: '5px', marginTop: '40px' }}>{item.node.title}</span>
        <span className="cart-item-variant" style={{ display: 'block', fontSize: '12px', color: '#888', marginBottom: '5px' }}>{item.node.variant.title}</span>
        <span className="cart-item-price" style={{ display: 'block' }}>{item.node.variant.priceV2.amount} kr</span>
        <div className="cart-item-quantity" style={{ marginTop: '20px', display:'flex', flexDirection:'row' }}>
          <button
            className="cart-item-remove-button"
            onClick={handleRemoveClick} // Wrapped remove handler
            style={{ border: 0 }}
            disabled={loading} // Disable button while loading
          >
            <img style={{ width: '20px' }} src='/bin.png' alt="Remove item" />
          </button>
        <div className="cart-item-quantity" style={{ marginLeft: '10px', display: 'flex', position:'absolute', right:'0' }}>
        <label htmlFor={`quantity-${item.node.id}`} style={{ marginRight: '5px', paddingBottom: '0px', fontWeight: '700', marginTop:'auto', marginBottom:'auto' }}>Antal:</label>
        <select
          id={`quantity-${item.node.id}`}
          value={item.node.quantity}
          onChange={handleQuantityChange} // Wrapped quantity change handler
          style={{ border: '0', fontWeight: '700', fontSize: '14px' }}
          disabled={loading} // Disable select while loading
        >
          {Array.from({ length: 10 }, (_, i) => i + 1).map((quantity) => (
            <option key={quantity} value={quantity}>{quantity}</option>
          ))}
        </select>
      </div>
        </div>
      </div>

      {/* Quantity Selector */}

    </li>
  );
};

export default CartItem;
