import React, { useState, useEffect, memo, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import '../ProductView.css';
import ProductCarousel from './ProductCarousel';
import ProductMenu from './ProductMenu';
import { useProductContext } from '../../../components/products/ProductContext';
import ProductContainer from '../../HomePage/components/ProductContainer';
import ProductShell from '../../../components/products/ProductShell';

const ProductHandler = ({color, products, variantImages, language, checkoutId, setCheckout, setCheckoutId}) => {

  const [chosenProduct, setChosenProduct] = useState(color);

  return (
    <>      
      <div className="productContainer">
        <ProductCarousel
          chosenProduct={chosenProduct}
          variantImages={variantImages}
          variants={products.variants.edges}
        />
        <ProductMenu
          products={products}
          setChosenProduct={setChosenProduct}
          chosenProduct={chosenProduct}
          language={language}
          checkoutId={checkoutId}
          setCheckout={setCheckout}
          setCheckoutId={setCheckoutId}
        />
      </div>
      <ProductContainer id='ig' title={'Få inspiration!'} comment={'Nämn @bxhaled på Instagram för en chans att få visa upp din look'}>
        <p style={{marginTop:'-40px'}}>Nämn @bxhaled på Instagram för en chans att få visa upp din look</p>
        <div style={{ display: 'inline-block', width: '400px', height: 'auto', paddingRight: '20px', overflow: 'hidden' }} className='igPicContainer'>
          <img src='/bxhaled4.jpg' style={{ width: '100%', height: '400px', objectFit: 'cover' }} onClick={() => { window.open('https://www.instagram.com/bxhaled/', '_blank'); }}  />
          <h4 style={{ color: 'black' }}>@bxhaled</h4>
        </div>
        <div style={{ display: 'inline-block', width: '400px', height: 'auto', paddingRight: '20px', overflow: 'hidden' }} className='igPicContainer'>
          <img src='/ig-greenland.png' style={{ width: '100%', height: '400px', objectFit: 'cover' }} onClick={() => { window.open('https://www.instagram.com/bxhaled/', '_blank'); }}  />
          <h4 style={{ color: 'black' }}>@bxhaled</h4>
        </div>
        <div style={{ display: 'inline-block', width: '400px', height: 'auto', paddingRight: '20px', overflow: 'hidden' }} className='igPicContainer'>
          <img src='/bxhaled1.jpg' style={{ width: '100%', height: '400px', objectFit: 'cover' }} onClick={() => { window.open('https://www.instagram.com/bxhaled/', '_blank'); }}  />
          <h4 style={{ color: 'black' }}>@bxhaled</h4>
        </div>
      </ProductContainer>
    </>
  );
};

export default memo(ProductHandler);
