import { createCheckout, getProductDetail } from "../../../backend/shopify/shopifyService";
import { useState } from "react";
import { useProductContext } from "../../../components/products/ProductContext";
import { getProducts } from "../../../backend/shopify/shopifyService";
import { useEffect } from "react";
import { FindProductId, findProductId } from "../../../backend/shopify/services/Product";
import Dropdown from "../../../components/products/Dropdown";
import './ProductMenu.css'

function ProductMenu({products, setChosenProduct, chosenProduct, language}) {
  const { checkoutId, checkout, handleAddToCart } = useProductContext();
  const [cart, setCart] = useState([]);
  const [product, setProduct] = useState([]);

  const [variantImages, setVariantImages] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [quantity, setQuantity] = useState(1);

  // Function to handle quantity increase
  const handleIncreaseQuantity = () => {
      setQuantity(quantity + 1);
  };

  // Function to handle quantity decrease (prevent going below 1)
  const handleDecreaseQuantity = () => {
      if (quantity > 1) {
          setQuantity(quantity - 1);
      }
  };

  const selectedVariant = products.variants.edges.find(
    (variant) => variant.node.title === chosenProduct
  );

  console.log(products)
  // Set variantId to the ID of the selected variant
  const variantId = selectedVariant ? selectedVariant.node.id : null;


  const handleAddProductToCart = () => {
    if (variantId) {
      setQuantity(1)
      handleAddToCart(variantId, quantity); // Add product to cart using the context method
    }
  };

  const handleCheckout = () => {
    if (checkout) {
        window.location.href = checkout.webUrl; // Only try to access checkout if it exists
    } else {
        console.log('No checkout available');
    }
  };



  const clicker = (node) => {
    console.log(node)
  };


  if (error) return <p>Error loading product details: {error.message}</p>;

  if (!products) return <p>No product found</p>;


  return (
    <div className='productMenu' style={{ marginTop: "0px"}}>
      <div className='productMenuInner'>
          <div>
            <div>
              <h3 style={{margin: '0'}}>{products.title}</h3>
              <h4 style={{color:'#373737', marginTop: '10px'}}>{products.variants.edges[0].node.priceV2.amount}kr</h4>
            </div>

            <p id='descrtext' style={{ color:'#6D6D6D'}}>
                {products.description}
            </p>
          </div>
          <div style={{display: 'flex', alignItems: 'center'}}>
            <h4 style={{color:'#373737', marginBottom:'10px'}}>Färg&nbsp;</h4>
            <h4 style={{color:'#373737', marginBottom:'10px'}}>- {language(`product.${chosenProduct}`)}</h4>
          </div>
          {products.variants.edges.length > 0 ? (
            <div style={{display:'flex', alignItems:'center'}}>
              {products.variants.edges.map((variantEdge, index) => (
                <div key={index}>
                  <div className={`productButton ${chosenProduct === variantEdge.node.title ? 'active' : 'inactive'}`} onClick={() => (setChosenProduct(variantEdge.node.title), console.log(variantEdge.node))} style={{backgroundColor:variantEdge.node.title}}></div>
                </div>
              ))}
            </div>
          ) : (
            <p>No variants available</p>
          )}
          <div style={{ marginTop: "50px", width: '100%', display:'flex', gap:'20px' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                  <button
                      className="incButton"
                      onClick={handleDecreaseQuantity} 
                      style={{ width: '40px', height: '40px', fontSize: '20px', borderRadius: '10%', border:'0' }}>
                      -
                  </button>
                  <input 
                      type="int" 
                      value={quantity} 
                      style={{ width: '50px', height:'30px', textAlign: 'center', margin: '0 10px', fontSize: '18px' }} 
                  />
                  <button
                      className="incButton"
                      onClick={handleIncreaseQuantity} 
                      style={{ width: '40px', height: '40px', fontSize: '20px', borderRadius: '10%', border:'0' }}>
                      +
                  </button>
              </div>
              <button onClick={handleAddProductToCart} style={{ height: '50px', width: "200px", backgroundColor:'#292929', color:'white', borderRadius:'10px' }}>Add to Cart</button>

          </div>
          <Dropdown title="Produktdetaljer" >
            <p style={{color:'#6D6D6D'}}>{products.description}</p>
          </Dropdown>

          <Dropdown title="Leveransinformation">
            <p style={{color:'#6D6D6D'}}>Leverans inom 3-5 arbetsdagar. Skickas samma dag om det beställs innan 12.</p>
            <p style={{color:'#6D6D6D'}}>Gratis returer inom 30 dagar.</p>
          </Dropdown>
      </div>
    </div>
  );
}

export default ProductMenu;